import React from 'react'
import { FormattedMessage } from 'react-intl'
import MaterialIcon from '@material/react-material-icon'

const InfoBox = ({ icon, children, ...props }) => (
  <div className="info-box">
    <div className="info-box__icon">{icon && <MaterialIcon icon={icon} />}</div>
    <div className="info-box__content">{children}</div>
  </div>
)

const IdentityCard = () => (
  <div className="identity-card">
    <InfoBox icon="mail_outline">
      Informatika Mihelac, Bojan Mihelač s.p.
      <br />
Štihova 20
      <br />
1000 Ljubljana, SI Slovenija
    </InfoBox>

    <InfoBox icon="public">www.informatikamihelac.com</InfoBox>

    <InfoBox icon="alternate_email">info @ informatikamihelac.com</InfoBox>

    <InfoBox icon="business">
      VAT ID: SI25427997<br /> Company number: 1891103<br /> Bank account: SI56 6100 0002 5024 213, SWIFT BIC: HDELSI22
    </InfoBox>
  </div>
)

export default IdentityCard
