import React from 'react'
import { FormattedMessage } from 'react-intl'
import { graphql } from 'gatsby'
import PageLayout from '../../../components/layout'

import Img from 'gatsby-image'
import PageSummary from '../../../components/PageSummary'
import IdentityCard from '../../../components/IdentityCard'
import Hr from '../../../components/Hr'
import { BlockForPage } from '../../../components/DefaultPage'
import { ImgIconLink, MaterialIconLink } from '../../../components/IconLink'
import injectNestedIntlProvider from '../../../components/NestedIntlProvider'
import MarkdownBlockFromTranslation from '../../../components/MarkdownBlock'

import githubUrl from '../../../images/icons/github.svg'
import linkedinUrl from '../../../images/icons/linkedin.svg'

const IndexPage = ({ data }) => (
  <FormattedMessage id="pages.about.title">
    {title => (
      <PageLayout
        title={title}
        pageSummary={
          <PageSummary
            title={title}
            lead={<MarkdownBlockFromTranslation id="pages.about.lead" />}
            imageFluid={data.summaryImage && data.summaryImage.childImageSharp.fluid}
          />
        }
        pageClassName="page--about"
      >
        <h4>
          <FormattedMessage id="pages.info.title" />
        </h4>
        <IdentityCard />

        <Hr />

        <div className="page__team">
          <h4>
            <FormattedMessage id="pages.about.team" />
          </h4>

          <div className="team">
            <div className="team__member">
              <div className="team__member-image">
                <Img fixed={data.bmihelac.childImageSharp.fixed} />
              </div>
              <div className="team__member-content">
                <div className="team__member-name">Bojan Mihelač</div>
                <div className="team__links">
                  <MaterialIconLink
                    className="team__icon"
                    icon="mail"
                    link="mailto:bojan@informatikamihelac.com"
                    title="E-mail"
                  />
                  <ImgIconLink
                    className="team__icon"
                    src={linkedinUrl}
                    title="LinkedIn"
                    link="https://www.linkedin.com/in/bmihelac/"
                  />
                  <ImgIconLink
                    className="team__icon"
                    src={githubUrl}
                    title="GitHub"
                    link="https://github.com/bmihelac/"
                  />
                </div>
              </div>
            </div>
            <div className="team__member">
              <div className="team__member-image">
                <Img fixed={data.sm.childImageSharp.fixed} />
              </div>
              <div className="team__member-content">
                <div className="team__member-name">Snježana Vračar Mihelač</div>
                <div className="team__links">
                  <MaterialIconLink
                    className="team__icon"
                    icon="mail"
                    link="mailto:snjezana@informatikamihelac.com"
                    title="E-mail"
                  />
                  <ImgIconLink
                    className="team__icon"
                    src={linkedinUrl}
                    title="LinkedIn"
                    link="https://www.linkedin.com/in/snjezanavracarmihelac/"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Hr />
        <BlockForPage code="open_source" link="about/open-source/" />
        <Hr />
        <BlockForPage code="sponsorship" link="about/sponsorship/" />
        <Hr />
        <BlockForPage
          code="privacy_statement"
          link="about/privacy-statement/"
        />
      </PageLayout>
    )}
  </FormattedMessage>
)

export default injectNestedIntlProvider(IndexPage)

export const query = graphql`
  query($language: String!) {
#    summaryImage: file(relativePath: { eq: "bck-aboutus.jpg" }) {
#      ...SummaryImage
#    }
    bmihelac: file(relativePath: { eq: "team/bmihelac.jpg" }) {
      ...TeamImage
    }
    sm: file(relativePath: { eq: "team/sm.jpg" }) {
      ...TeamImage
    }
    messages: allKeyValue(
      filter: {
        file: { relativeDirectory: { eq: "pages" }, name: { eq: $language } }
        key: {
          regex: "/^(pages\\.about)|(pages\\.(info|sponsorship|open_source|sponsorship|privacy_statement)\\.(title|lead))/"
        }
      }
    ) {
      edges {
        node {
          key
          value
        }
      }
    }
  }
`
