import React from 'react'
import MaterialIcon from '@material/react-material-icon'
import IconButton from '@material/react-icon-button'

const IconLink = ({children, link}) => (
  <IconButton isLink href={link} target="_blank" rel="noopener noreferrer">
    {children}
  </IconButton>
)

const ImgIconLink = ({className, src, title, ...otherProps}) => (
  <IconLink {...otherProps}>
    <img className={className} src={src} alt={title} />
  </IconLink>
)

const MaterialIconLink = ({className, icon, title, ...otherProps}) => (
  <IconLink alt={title} {...otherProps}>
    <MaterialIcon className={className} icon={icon}/>
  </IconLink>
)


export {IconLink as default, ImgIconLink, MaterialIconLink}
